<template>
    <v-row>
        <v-col cols="12">
            <!-- <v-row dense>
                <v-col cols="12">
                    <p class="primary--text small--text">What are you signing up for? (select multiple)</p>
                    <buttons-field v-model="model.type" :options="options"></buttons-field>
                </v-col>
            </v-row> -->

            <template>
                <v-row dense>
                    <v-col cols="12">
                        <hr />
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" sm="6">
                        <p class="primary--text small--text">First Name</p>
                        <v-text-field
                            ref="firstName"
                            v-model="model.firstName"
                            :disabled="loading"
                            type="text"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            label=""
                            placeholder="Enter your first name..."
                            required
                            :rules="[rules.required]"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <p class="primary--text small--text">Last Name</p>
                        <v-text-field
                            ref="lastName"
                            v-model="model.lastName"
                            :disabled="loading"
                            type="text"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            label=""
                            placeholder="Enter your last name..."
                            required
                            :rules="[rules.required]"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" v-if="allowEmailChange">
                        <p class="primary--text small--text">Email Address</p>
                        <v-text-field
                            ref="email"
                            v-model="model.email"
                            type="email"
                            :disabled="loading"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            prepend-inner-icon="mdi-email-outline"
                            label=""
                            placeholder="Please enter your email..."
                            required
                            :rules="[rules.required, rules.email]"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <p class="primary--text small--text">Phone Number</p>
                        <v-text-field
                            ref="phone"
                            v-model="model.phoneNumber"
                            type="text"
                            :disabled="loading"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            prepend-inner-icon="mdi-phone-outline"
                            label=""
                            placeholder="Please enter your phone number..."
                            required
                            :rules="[rules.required]"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <p class="primary--text small--text">Postal Address (optional)</p>
                        <v-text-field
                            ref="address"
                            id="address-input"
                            v-model="model.address"
                            @change="updateAddress"
                            type="text"
                            :disabled="loading"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            prepend-inner-icon="mdi-map-outline"
                            label=""
                            placeholder="Please enter your postal address..."
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <p class="primary--text small--text">Country of Residence (optional)</p>

                        <v-autocomplete
                            ref="country"
                            v-model="model.country"
                            :disabled="loading"
                            label=""
                            placeholder="Select your country of residence..."
                            :items="countries"
                            item-text="name"
                            item-value="name"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                        >
                            <template v-slot:item="{ item }">
                                <v-icon class="mr-4">{{ item.emoji }}</v-icon>

                                <v-list-item-content>
                                    <v-list-item-title class="small--text">{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <template v-slot:selection="{ item }">
                                <v-icon class="mr-4">{{ item.emoji }}</v-icon>

                                <v-list-item-content>
                                    <v-list-item-title class="small--text">{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <hr />
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <p class="primary--text small--text">Facebook (optional)</p>
                        <v-text-field
                            ref="facebook"
                            v-model="model.facebook"
                            type="text"
                            :disabled="loading"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            prepend-inner-icon="mdi-facebook"
                            label=""
                            placeholder="Please enter your Facebook link..."
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <p class="primary--text small--text">Twitter (optional)</p>
                        <v-text-field
                            ref="facebook"
                            v-model="model.twitter"
                            type="text"
                            :disabled="loading"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            prepend-inner-icon="mdi-twitter"
                            label=""
                            placeholder="Please enter your Twitter link..."
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <p class="primary--text small--text">Instagram (optional)</p>
                        <v-text-field
                            ref="facebook"
                            v-model="model.instagram"
                            type="text"
                            :disabled="loading"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            prepend-inner-icon="mdi-instagram"
                            label=""
                            placeholder="Please enter your Instagram link..."
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <p class="primary--text small--text">Website (optional)</p>
                        <v-text-field
                            ref="facebook"
                            v-model="model.website"
                            type="text"
                            :disabled="loading"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            prepend-inner-icon="mdi-web"
                            label=""
                            placeholder="Please enter your website link..."
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <p class="primary--text small--text">Any other link (optional)</p>
                        <v-text-field
                            ref="facebook"
                            v-model="model.other"
                            type="text"
                            :disabled="loading"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            prepend-inner-icon="mdi-link"
                            label=""
                            placeholder="Please enter any other link..."
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- <v-row dense>
                    <v-col cols="12">
                        <hr />
                    </v-col>
                </v-row> -->

                <!-- <v-row class="mb-6" dense>
                    <v-col cols="12">
                        <p class="primary--text small--text">Are you a student?</p>

                        <v-checkbox v-model="model.student" hide-details>
                            <template v-slot:label>
                                <span class="small--text">Student </span>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row> -->

                <v-row dense v-if="orgData?.data?.flags?.askInternational">
                    <v-col cols="12">
                        <p class="primary--text small--text">Are you an:</p>

                        <v-radio-group hide-details v-model="model.entrant">
                            <v-radio value="Australian Entrant">
                                <template v-slot:label>
                                    <span class="small--text">Australian Entrant</span>
                                </template>
                            </v-radio>

                            <v-radio value="International Entrant">
                                <template v-slot:label>
                                    <span class="small--text">International Entrant</span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>

                <slot name="password"></slot>
            </template>
        </v-col>
    </v-row>
</template>

<script>
import Countries from "@/modules/countries.json";

import ButtonsField from "@/components/form-field/buttons-field";

export default {
    name: "profile-form",

    props: {
        value: {
            type: Object,
        },
        loading: {
            type: Boolean,
        },
        allowEmailChange: {
            type: Boolean,
        },
    },

    components: {
        ButtonsField,
    },

    data() {
        return {
            model: this.value,

            rules: {
                required: (value) => !!value || "This field is required",
                minLength3: (value) => value.length >= 3 || "Min 3 characters",
                minLength4: (value) => value.length >= 4 || "Min 4 characters",
                minLength6: (value) => value.length >= 6 || "Min 6 characters",
                isMatch: (value) => value === this.confirmPassword,
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },

    computed: {
        orgData() {
            return this.$store.getters["org/getOrgData"];
        },
        countries() {
            return Object.keys(Countries).map((key) => {
                return {
                    name: Countries[key].name,
                    code: key,
                    emoji: Countries[key].emoji,
                    unicode: Countries[key].unicode,
                    image: Countries[key].image,
                };
            });
        },
        options() {
            const options = [];
            console.log("ORG", this.orgData);
            if (this.orgData.data.compTypes.digital) {
                options.push({
                    title: "Digital Awards",
                    key: "digital",
                });
            }
            if (this.orgData.data.compTypes.print) {
                options.push({
                    title: "Print Awards",
                    key: "print",
                });
            }
            console.log("OPTIONS", options);
            return options;
        },
    },

    methods: {
        hasKey(key) {
            return this.model.type.some((type) => type && type.key == key);
        },
        updateAddress(value) {
            const input = document.getElementById("address-input");
            setTimeout(() => (this.model.address = input.value), 300);
        },
    },

    watch: {
        "model.address": {
            immediate: true,
            handler: function (v) {
                if (v && v.length) {
                    this.$nextTick(() => {
                        const input = document.getElementById("address-input");

                        const options = {
                            fields: ["address_components", "geometry", "icon", "name"],
                            strictBounds: false,
                        };

                        const google = window.google;

                        const autocomplete = new google.maps.places.Autocomplete(input, options);
                        console.log(autocomplete);
                    });
                }
            },
        },
        model: {
            deep: true,
            handler: function (v) {
                this.$emit("input", v);
            },
        },
    },
};
</script>
