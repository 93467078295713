<template>
<div class="buttons-outer">
    <p class="primary--text small--text" v-if="title">{{title}}</p>

    <div class="buttons">
        <div class="button-outer" v-for="(option, index) in options" :key="index">
            <v-card class="button elevation-0 px-3 d-flex flex-column align-center" :class="{'active' : hasKey(option)}" @click="setModel(option)">
                <b>{{option.title}}</b>
            </v-card>
        </div>
    </div>
</div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'buttons-field',

    props: {
        value: [String, Object, Number, Array],
        title: String,
        options: Array,
        disabled: Boolean,
        loading: Boolean,
    },

    data() {
        return {
            model: this.value,
            error: false,
            processing: false,
            valid: false
        };
    },

    methods: {
        hasKey(option) {
            if (this.model) {
                return this.model.some(model => model.key == option.key);
            }

            return false;
        },
        setModel(option) {
            let match = this.hasKey(option);

            if (match) {
                let index = this.model.findIndex(model => model.key === option.key);
                this.model.splice(index, 1);
            } else {
                this.model.push(option);
            }

            this.$emit('input', this.model);
        }
    },

    watch: {
        model(v) {
            this.$emit('input', v);
        },
    }
}
</script>

<style lang="scss">
.buttons-outer {
    .buttons {
        margin: -12px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        // flex-wrap: wrap;

        .button-outer {
            padding: 12px;
            height: 100px;
            width: 100%;

            .button {
                border: 1px solid var(--v-grey-base);
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                &.active {
                    background: var(--v-primary-base);

                    b,
                    p {
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}
</style>
